<template>
  <div class="container">
    <div style="display: flex;justify-content: space-between;align-items: center;padding: 10px;">
        <div style="display: flex;align-items: center">
            <van-dropdown-menu>
                <van-dropdown-item v-model="value1" :options="option1" />
            </van-dropdown-menu>
            <van-dropdown-menu>
                <van-dropdown-item v-model="value2" :options="option2" />
            </van-dropdown-menu>
<!--            <div class="submit" @click="getUserActivityReward">Consulta</div>-->
        </div>
        <span>Bônus: <span style="color:#ffaa09">{{ sumReward |formatNumber }}</span></span>
    </div>
      <div class="history-item">
          <div :class="{'c1':true,'back':index % 2 !=0}" v-for="(item,index) in historyList">
              <div class="c1-1">
                  <div class="c1-1-1" style="margin:10px 0">{{item.orderCode}}</div>
                  <div class="c1-1-2">
                      <div>RS:  <span style="color:#ffaa09">{{ item.changeBalance }}</span></div>
                      <div>{{item.createTime}}</div>
                      <div>Fonte: <span style="color:#ffaa09">Prêmio de atividade</span></div>
                  </div>
              </div>
              <div :class="{'green':true}">Resgatado</div>
          </div>
      </div>

    <tabbar></tabbar>
  </div>
</template>


<script>
import tabbar from "@/components/Tabbar.vue";
import {getUserActivityReward, getUserInfoApi} from "@/api";

export default {
  data() {
    return {
        value1: 6,
        option1: [
            { text: 'Hoje', value: 0 },
            { text: 'Ontem', value: 1 },
            { text: 'Últimos 7 Dias', value: 2 },
            { text: 'Últimos 15 Dias', value: 3 },
            { text: 'Últimos 30 Dias', value: 4 },
            { text: 'Quase 60 dias', value: 5 },
            { text: 'Todos', value: 6 }
        ],
        value2: '',
        option2: [
            { text: 'Sacar dinheiro', value: 1 },
            { text: 'Retirada e reembolso', value: 2 },
            { text: 'Jogo para vencer', value: 3 },
            { text: 'Jogo perdido', value: 4 },
            { text: 'Participação nos lucros da agência', value: 5 },
            { text: 'Recompensa do primeiro depósito', value: 6 },
            { text: 'Recompensas de baú', value: 7 },
            { text: 'Recompensa em dinheiro de resgate', value: 8 },
            { text: 'Primeira recompensa de recarga da história', value: 9 },
            { text: 'Recompensas de recarga cumulativa', value: 10 },
            { text: 'Recompensas de apostas cumulativas', value: 11 },
            { text: 'Recompensas cumulativas de login', value: 12 },
            { text: 'Recompensas do evento carrossel', value: 13 },
            { text: 'Recompensas de atualização VIP', value: 15 },
            { text: 'Resgatar código', value: 18 }
        ],
        historyList:[],
        sumReward:0,
        userInfo:[],
    }
  },
  components: {tabbar},
    watch: {
        value1(newValue) {
            this.getUserActivityReward();
        },
        value2(newValue) {
            this.getUserActivityReward();
        }
    },
    mounted() {
        if(this.$route.query.num!=null){
            this.value2 = this.$route.query.num
        }else {
            this.value2 = 1;
        }
        console.log("this.value2:"+this.value2)
        this.getUserInfoApi();
    },
    methods:{
        getUserInfoApi(){
            let that = this
            getUserInfoApi().then(res=>{
                if (res.code ==200){
                    that.userInfo = res.data
                    that.getUserActivityReward();
                }
            })
        },
        getUserActivityReward(){
            if(this.userInfo.id){
                var data = {
                    userId:this.userInfo.id,
                    dayType:this.value1,
                    activityType:this.value2,
                }
            }else{
                var data = {
                    userId:'',
                    dayType:'',
                    activityType:'',
                }
            }
            getUserActivityReward(data).then(res=>{
                if (res.code==200){
                    this.historyList = res.data.pgUserBillVos
                    this.sumReward = res.data.sumReward
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.container{
    background: #303030;
    min-height: 100vh;
}
.submit{
    background: #ffaa09;
    padding: 5px;
    border-radius: 5px;
    color: #fff;
    margin-left: 10px;
}
.history-item{
    max-height: 655px;
    overflow: hidden;
    overflow-y: auto;
    .c1{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #1c1c1c;
        padding: 10px;
        margin: 5px 10px;
        border-radius: 10px;
        .c1-1{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .c1-1-1{

            }
            .c1-1-2{
                display: flex;
                font-size: 8px;
                div{
                    margin-right:5px
                }
            }
        }
        .red{
            color: red;
        }
        .green{
            color: #04be02;
        }
    }
    .back{
        background: transparent;
    }
}
::v-deep(.van-dropdown-menu) {
    width: 100px;
}
::v-deep(.van-dropdown-menu__bar) {
    width: 90px;
    border-radius: 30px;
}
::v-deep(.van-dropdown-menu__item) {
    background: #000;
    border-radius: 30px;
}
::v-deep(.van-dropdown-item__option) {
    background: #000;
}
@media screen and (min-width: 768px) {
    ::v-deep(.van-dropdown-item__content) {
        position: absolute;
        max-height: 80%;
        width: 435px;
        left: 50%;
        transform: translateX(-50%);
    }
}
</style>
